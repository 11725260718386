.tr_score_a {
    background-color: #e1ffe0;
}

.tr_score_B {
    background-color: #fcffd6;
}

.tr_score_C {
    background-color: #ffefef;
}

.info_td_details {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 165px;
}