.bar {
    display: flex;
    height: 3.5rem;
    overflow: hidden;
    font-size: 13px;
    background-color: #fff;
    border-radius: .25rem;
}

.progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: #e9e9e9;
    font-size: 12px;
    text-align: center;
    white-space: nowrap;
    background-color: #2f67bb;
    transition: width .6s ease;
}

.progress-bar:nth-child(2) {
    background-color: #2f8dcf;
}

.progress-bar:nth-child(3) {
    background-color: #2fa3cf;
}

.progress-bar:nth-child(4) {
    background-color: #a02fcf;
}

.progress-bar:nth-child(5) {
    background-color: #7144bd;
}

.progress-text {
    color: #e3e3e3;
    font-size: 18px;
    line-height: 1.2;
}