.h-icon {
    fill: #98999a;
    border-radius: 7px;
    height: 24px;
    width: 24px;
}

.h-icon:hover {
    fill: #777777;
}

.new_ideas {
    background: url('../../../public/icons/new_ideas.svg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 32em;
    height: 21em;
}

.not_found {
    background: url('../../../public/icons/not_found.svg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 32em;
    height: 21em;
}

.select_options {
    background: url('../../../public/icons/select_options.svg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 32em;
    height: 21em;
}

.no_items {
    background: url('../../../public/icons/no_items.svg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 32em;
    height: 21em;
}

.no_orders {
    background: url('../../../public/icons/void.svg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 32em;
    height: 21em;
}

.store__icon-yam {
    background: url('../../../public/icons/yam.png');
    background-size: cover;
}

.store__icon-wb {
    background: url('../../../public/icons/wb.png');
    background-size: cover;
}

.store__icon-ozon {
    background: url('../../../public/icons/ozon.png');
    background-size: cover;
}


/* https://undraw.co/illustrations */