.bg_green {
    background-color: #1fcf64;
    background-image: url('../../../public/bg-green.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    height: 200px;
    border-radius: 0.625rem;
    padding-left: 2.25rem;
    padding-right: 2.25rem;
    padding-top: 1.5rem;
}

.text-revenue {
    text-align: center;
    color: white;
}

.text-revenue>h1 {
    font-size: 2.5rem;
    margin: 0.17em 0;
}

.text-revenue>h2 {
    font-size: 0.95rem;
}

.info_list {
    padding: 2.25em 1em;
    margin-top: -100px;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    margin-bottom: 1.5rem;
    box-shadow: 0 0.1rem 0.75rem 0.25rem rgba(0, 0, 0, 0.05);
}

.info_item:last-child {
    margin-bottom: 0;
}

.info_symbol {
    width: 40px;
    height: 40px;
    border-radius: 0.475rem;
    background-color: #F9F9F9;
    margin-right: 1.25rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.info_item {
    display: flex;
    align-items: center;
    margin-bottom: 2.25rem;
}

.info_content {
    line-height: 1.4;
}

.info_content>h3 {
    font-size: 15px;
    font-weight: 400;
}

.info_content>h4 {
    font-weight: 500;
    font-size: 13px;
    color: #9c9ca3;
}

.info_result {
    margin-left: auto;
}

.echart_linear {
    padding: 10px 0px 7px 0px;
}

.info_result>h2 {
    font-size: 1.22rem;
}

.e_load_container {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
}

.sk_small_flex {
    flex-shrink: 0;
    flex-grow: 1;
}

.live_map-circle {
    transform: translateX(-50%) translateY(-50%);
    width: 7px;
    height: 7px;

    background-color: rgb(7, 185, 7);
    border-radius: 50%;
    animation: flash 5s infinite;
    margin-top: 9px;
    margin-left: 9px;
}

@keyframes flash {

    0%,
    100% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }
}

.container-title-live-map {
    font-size: 1.35em;
    font-weight: 500;
    line-height: 2;
    display: flex;
    align-items: center;
}

.container>.row {
    margin-bottom: 1em;
}