table {
    border-collapse: collapse;
    width: 100%;
}

td,
th {
    border: 1px solid #ebe9e9;

    text-align: left;
    padding: 8px;
}

th {
    font-size: 12px;
}


/* no border */
.border-none {
    border-collapse: collapse;
    border: none;
}

.border-none tr:first-child td {
    border-top: none;
}

.border-none tr:last-child td {
    border-bottom: none;
}

.border-none tr td:first-child {
    border-left: none;
    max-width: 15em;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 13.2px;

}

.border-none tr td:last-child {
    border-right: none;
}

.no-pad {
    padding: 0;
}


.tr-hover:hover {
    background: #f6f6f6;
}

.td-symbol {
    color: #888;
    font-size: 13px;
}

.td_marked::marker {
    content: '• ';
    line-height: 0.12em;
    color: #a9a9a9;
    display: flex;
    position: relative;
}