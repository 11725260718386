.stores_not-found {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2em 0;
}

.no-stores-title {
    font-size: 19px;
}

.button_new-store {
    margin-top: 14px;
}

.stores-container {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

/* store card */

.store {
    height: 145px;
    width: 256px;
    border: 1px solid #ccc;
    border-radius: 6px;
    padding: 20px;
    background: white;
}

.store__body {
    display: flex;
}

.store__title {
    margin-left: 10px;
    font-size: 20px;
    font-weight: 500;
    line-height: 42px;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 13rem;
    white-space: nowrap;
}

.store__icon {
    width: 36px;
    height: 36px;
    display: block;
}

.store__health {
    list-style: none;
    margin-left: 47px;
    font-size: 14px;
}

.store__health>li:nth-child(even) {
    margin-bottom: 5px;
    font-size: 13.3px;
    color: #6c6c6c;
}

.store__health>li:nth-child(odd) {
    font-weight: 600;
}

.store__actions {
    display: flex;
    gap: 5px;
    justify-content: flex-end;
    margin-top: 13px;
}

.store__action-new {
    background: #f9f9f9;
    display: flex;
    color: black;
    flex-direction: column;
    border: 1px dashed #ccc;
    /* Указан цвет границы для лучшей видимости */
    align-items: center;
    justify-content: center;
    line-height: 29px;
}

.store__action-new:hover {
    background: #f6f6f6;
}

.store-action-icon {
    display: flex;
    align-items: center;
}

.skeleton-container>span {
    gap: 10px;
    display: flex;
}