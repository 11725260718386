.app__footer {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 70px;
    width: 100%;
    background: #f0f1f2;
}

.app_footer_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding: 0 24px;
    border-top: 1px solid #cbcbcb;
}

.app_footer_logo {
    font-size: 12px;
}