.form-group:first-child {
    margin-top: 0;
}

.form-group:last-child {
    margin-bottom: 0;
}

.yid-form {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.yid-form label .form_radio_btn input[type=radio]:checked+label {
    background: rgb(235 255 236);
    border: 1px solid #279927;
}

.yid-label {
    width: 22rem;
    padding: 7px 14px;
}

.yid-label>h2 {
    font-size: 18px;
    line-height: 1.8;
}

.yid-label>p {
    line-height: 2;
    font-size: 13px;
}

.help-text {
    margin: 1em 0;
}