.alert {
    padding: 12px;
    border-radius: 12px;
    font-size: 14px;
    color: #ffffff;
    margin-bottom: 18px;
    text-align: left;
    border: 1px solid;
    flex-direction: column;
    flex-wrap: wrap;
}

.alert-success {
    background-color: #0c7124;
    border-color: #c3e6cb;
}

.alert-danger {
    background-color: #710c0c;
    border-color: #81374a;
}

.alert-show {
    display: flex;
}

.alert-hide {
    display: none;
}

.alert-close-button {
    position: absolute;
    right: 2em;
    z-index: 2;
    padding: 0;
    cursor: pointer;
    font-size: 1.4rem;
}

/* Light */

.alert>h3 {
    padding-bottom: 1em;
}

.alert-danger-light,
.alert-success-light,
.alert-warning-light,
.alert-info-light {
    border-radius: 6px;
}

.alert-danger-light {
    background-color: #ECC8C5;
    border-color: #d37f78;
    color: #B83C37;
}

.alert-success-light {
    background-color: #DDF3D5;
    border-color: #9ddc86;
    color: #597151;
}

.alert-warning-light {
    background-color: #F8F4D5;
    border-color: #e9dd7e;
    color: #96722E;
}

.alert-info-light {
    background-color: #CDE8F6;
    border-color: #2697d1;
    color: #447EAF;
}

.alert__button-container {
    display: flex;
}