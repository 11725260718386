.auth {
    padding: 0;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    background-size: cover;
    background-position: 50%;
    min-height: 100vh;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;

    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;

}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}


.mask {
    background-image: linear-gradient(310deg, #141727, #3a416f);
    position: absolute;
    background-size: cover;
    background-position: 50%;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: .8;
}

.auth__container {
    margin: 0 auto;
    display: flex;
    z-index: 1;
}

.auth__form {
    width: 340px;
    background: #fff;
    border-radius: 7px;
    border: 1px solid #151515c9;
}

.auth__title {
    font-weight: 400;
    color: #1a202c;
    font-size: 1.875rem;
    text-align: center;
}

.auth__form-elements {
    padding: 0 19px;
    margin: 1.57em 0;
}

.auth__button {
    width: calc(100%);
    margin-top: 24px;
}

.auth__text {
    font-size: 14px;
    text-align: center;
    display: block;
    margin: 20px 0px;
}

.auth__logo {
    background: url('../../../public/logo_muted.png');
    background-repeat: no-repeat;
    background-position: center center;
    height: 71px;
    width: 150px;
    margin-bottom: 22px;
    background-size: contain;
    z-index: 1;
    opacity: 0.3;
}

.auth__logo:hover {
    opacity: 1;
}

.error-message {
    margin-top: 3px;
    display: block;
    color: #e01111;
}