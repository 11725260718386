.pagination {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  margin-top: 1.6em;
  margin-right: 1.6em;
  padding-bottom: 1.6em;
}

.pagination>li {
  display: inline-block;
  list-style: none;
  padding-left: 0;
  cursor: pointer;
}

.page-item {
  margin-right: 0.5rem;
}

.pagination>li>a,
.pagination>li>span {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.475rem;
  height: 33px;
  min-width: 33px;
  font-weight: 500;
}

.pagination>li.active>a {
  color: #fff;
  background-color: #2a93ed;
}

/* Style the active class (and buttons on mouse-over) */
.previous button:hover,
.next button:hover,
.pagination>li:not(.active):not(.disabled)>a:hover {
  background-color: #F9F9F9;
  color: #00A3FF;
}

.previous>a {
  font-size: 18px;
}