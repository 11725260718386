.payments-types {
    width: 200px;
    height: 17px;
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url('../../../public/assets/payments.png');
}

.action-info {
    display: flex;
    gap: 1em;
    flex-direction: column;
    align-items: center;
}

.tariffs-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 2.5em;
}

.package-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 1em 0px 2em 0px;
}

.package-content {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.package-title {
    text-align: center;
    margin-bottom: 1em;
    width: 80%;
}

.package-description {
    color: #686868;
    font-size: 16px;
}

.package-prices {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 0em 0.5em;
    align-items: flex-end;
    max-height: calc(2* 100px);
    overflow: hidden;
    justify-content: center;
    margin-bottom: 2em;
    margin-top: 1em;
}

.package-price-old {
    text-decoration: line-through;
    color: #6495b1;
    font-size: 1.5rem;
}

.package-price-new {
    font-size: 39px;
    color: #0ba2f7;
    font-weight: 800;
}

.package-title-text {
    font-weight: 700;
    font-size: 27px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    gap: 0.5em;
    flex-direction: row-reverse;
}

.package-price-info {
    color: #a1a1a1;
}

.package-price-new>.package-price-symbol {
    font-size: 24px;
}

.package-price-old>.package-price-symbol {
    font-size: 1.2rem;
}

.icon-yes {
    color: #50CD89;
    font-size: 22px;
    background-color: #50cd8947;
    border-radius: 3em;
}

.package-features-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5em;
    margin-bottom: 10px;
    justify-content: space-between;
    max-width: 300px;
    color: #1d1c1e;
}

.package-features-text {
    display: flex;
    flex-direction: row;
    font-size: 15px;
    font-weight: 400;
}

.package-action {
    min-height: 42px;
    margin-top: 1em;
    text-align: center;
}

.package-features-title {
    margin: 5px 0px;
    display: flex;
    align-items: flex-start;
}

.package-icon {
    width: 50px;
    height: 50px;
    display: flex;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.emoji-unicorn {
    background-image: url('../../../public/emojies/unicorn.png');
}

.emoji-crown {
    background-image: url('../../../public/emojies/crown.png');
}

.emoji-rocket {
    background-image: url('../../../public/emojies/rocket.png');
}

.package-s1 {
    background-color: #f0f8ffbd;
    border-radius: 2em;
}

.package-s2 {
    background-color: #fcf0ff9c;
    border-radius: 2em;
}

.package-s3 {
    background-color: #ffebcd69;
    border-radius: 2em;
}

.tariffs-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 4em;
}

.tariffs-title-text {
    font-size: 3rem;
}

.package-icon-info {
    font-size: 16px;
    margin-right: 0.6em;
    color: #475056;
}

.tariffs-title-description {
    font-size: 15px;
}

.tariffs-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 6em;
    flex-direction: column;
    margin-bottom: 6em;
}

.tariffs-footer-invoice {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 3em;
    width: 50%;
    border-bottom: 1px dashed #DBDFE9;
}


.tariffs-footer-action {
    display: flex;
    margin-top: 1em;
    margin-bottom: 1em;
    width: 50%;
    flex-direction: row;
    gap: 1em;
    align-items: center;
    justify-content: space-between;
}

.tariffs-footer-invoice-price {
    font-weight: 400;
    font-size: 21px;
}

.tariffs-footer-action-options {
    display: flex;
    flex-direction: column;
    gap: 0.3em;
}

.tariffs-footer-invoice-description {
    font-size: 16px;
}

.btn_special_pay {
    padding: 1.5em 3em;
    background: #3d62b7;
}

.checkbox__ap {
    margin-top: 1em;
}

.package-container {
    border: 1px solid #fff;
}

.form_radio_tariffs label {
    cursor: pointer;
}

.form_radio_tariffs input[type=radio] {
    display: none;
}

.form_radio_tariffs input[type=radio]:checked+label>.package-s1 {
    background: #e0eefbbd;
    border: 1px dashed #58a0dd;
}


.form_radio_tariffs input[type=radio]:checked+label>.package-s2 {
    background: #fbecff;
    border: 1px dashed #cb7edd;
}


.form_radio_tariffs input[type=radio]:checked+label>.package-s3 {
    background: #ffedd1;
    border: 1px dashed #cf9a47;
}

.packege-icon-mp {
    width: 16px;
    height: 16px;
    margin-right: 0.6em;
}

.package-s1>.package-features>.package-features-content>.package-features-text>.package-icon-info {
    color: #28668f;
}

.package-s2>.package-features>.package-features-content>.package-features-text>.package-icon-info {
    color: #7f5e89;
}

.package-s3>.package-features>.package-features-content>.package-features-text>.package-icon-info {
    color: #957646;
}

.package-icon-primary {
    color: #b37524 !important;
}

.package-s2>.package-content>.package-prices>.package-price-new {
    color: #c654e5;
}

.package-s2>.package-content>.package-prices>.package-price-old {
    color: #965ba7;
}

.package-s3>.package-content>.package-prices>.package-price-new {
    color: #b37524;
}

.package-s3>.package-content>.package-prices>.package-price-old {
    color: #bf9155;
}

.button-icon {
    color: white;
    font-size: 16px;
}

.form_card_text {
    padding-top: 0.5em;
    padding-left: 30px;
}

.form_normal_text {
    font-size: 14px !;
}

/* checkbox */

.checkbox__body-small {
    font-size: 13px;
}

.checkbox__body-master {
    font-size: 18px;
}

.style-c {
    display: flex;
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    align-items: center;
}

.style-c input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.style-c input:checked~.checkbox__checkmark {
    background-color: #4670cf;
}

.style-c input:checked~.checkbox__checkmark:after {
    opacity: 1;
}

.style-c:hover input:checked~.checkbox__checkmark {
    background-color: #4670cf;
}

.style-c .checkbox__checkmark {
    position: absolute;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
    transition: background-color 0.25s ease;
    border-radius: 4px;
}

.style-c .checkbox__checkmark:after {
    content: "";
    position: absolute;
    left: 7px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    opacity: 0;
    transition: opacity 0.25s ease;
}

.style-c .checkbox__body {
    color: #333;
    line-height: 1.4;
}

.checkbox__checkmark.checkbox_i_small {
    height: 16px;
    width: 16px;
}

.checkbox__checkmark.checkbox_i_small:after {
    content: "";
    position: absolute;
    left: 5px;
    top: 3px;
    width: 4px;
    height: 6px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    opacity: 0;
    transition: opacity 0.15s ease;
}

.checkbox__checkmark.checkbox_i_small.checkbox_muted input~.checkbox_muted:after {
    opacity: 1;
}

.checkbox__checkmark.checkbox_i_small.checkbox_muted {
    background-color: #adbde7;
}

.style-c input:checked~.checkbox__checkmark.checkbox_i_small.checkbox_muted {
    background-color: #7290e3;
}

.badge-pay {
    color: #36B37E;
    background-color: #d7f0e5;
    padding: .85em 1.375em;
    border-radius: 50rem;
    font-size: 1em;
    font-weight: 600;
}

.checkbox__body-small>a {
    color: #1e5b91;
}