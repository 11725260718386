.cost-th {
    width: 162px;
}

.container-cost {
    display: flex;
    margin: auto 0;
    gap: 5px;
}

.button-accept {
    background: #4090bd;
}

.button-accept:hover {
    background: #3c82a9;
}

.button-cancel {
    background: #b3b3b3;
}

.button-cancel:hover {
    background: #979797;
}

.input-cost {
    width: 74px;
    border: none;
    border-radius: 6px;
    padding: 6px;
    background: #f4f4f4;
}

.input-cost:focus,
.input-cost:focus {
    outline: 1px solid #b9b9b9;
    background: #ffffff;
}

.container-cost button {
    padding: 4px;
}

.item_img {
    max-height: 36px;
    border-radius: 0.25em;
}

.item_td_content {
    display: flex;
    align-items: center;
    gap: 9px;
    border: none;
}