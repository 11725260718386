@import url('./vendor/normalize.css');
@import url('./vendor/fonts/fonts.css');

html {
  position: relative;
  min-height: 100%;
}

body {
  margin: 0 0 100px;
  font-family: 'Inter';
  font-size: 14px;
  background: #f1f2f3;
  color: #454545;
}

a {
  text-decoration: none;
  color: #102c4a;
}

h1 {
  font-weight: 500;
}

h2 {
  font-weight: 400;
}

.app__container {
  margin: 0 20px;
  margin-bottom: 10em;
}

.app__container-margin {
  margin-top: 5em;
}

.scrollable {
  overflow-y: scroll;
  overflow-x: auto;
  scrollbar-width: none;
  /* Скрывает полосы прокрутки в Firefox */
}

.scrollable::-webkit-scrollbar {
  width: 0;
  /* Убираем ширину вертикальной полосы */
  height: 0;
  /* Убираем высоту горизонтальной полосы */
  display: none;
  /* Скрывает полосы прокрутки в WebKit-браузерах (Chrome, Safari) */

}

.no-min-w {
  min-width: 30px !important;
}

td {
  text-align: left;
  /* Выравнивание текста в ячейках */
  white-space: nowrap;
  /* Предотвращает перенос текста */
}


@media screen and (max-width: 1255px) {
  .package-features-content {
    gap: 1em !important;
  }

  .tariffs-footer-invoice {
    width: 100% !important;
  }

  .tariffs-footer-action {
    width: 100% !important;
  }
}

@media screen and (max-width: 1025px) {
  .info_symbol {
    height: 1em !important;
    width: 1em !important;
  }

  .info_result>h2 {
    font-size: 1.1em !important;
  }

  .info_content>h3 {
    font-size: 0.95em !important;
  }

  .info_content>h4 {
    font-size: 0.85em !important;
  }

  .w_part_s {
    min-width: 320px !important;
  }

}