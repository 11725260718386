.svg-container {
    position: relative;
}

.russia-map {
    width: 80%;
    height: auto;
}

.high-tooltip {
    max-width: 300px;

    min-width: 230px;


    pointer-events: none;
    background: #000000e3;
    color: white;
    font-size: 13px;
    padding: 9px;
    font-weight: 400;
    border-radius: 0.35em;
    box-shadow: 0px 0px 1px 0px;
    transform: translateX(-50%);
    position: absolute;
    line-height: 1.3;

    -webkit-animation: fadein 1s;
    animation: fadein 1s;
}

@keyframes fadein {
    from {
        opacity: 0;
        margin-top: -20px;
        /* Начальное положение выше на 20px */
    }

    to {
        opacity: 1;
        margin-top: 0;
        /* Конечное положение на месте */
    }
}

.o-element {
    display: flex;
}

.o-title {
    font-size: 19px;
    color: rgb(10, 214, 3);
}

.o-items {
    font-size: 15px;
    color: rgb(255, 255, 255);
    /* Обрезка текста */
    display: block;
    /* or in-line block according to your requirement */
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.o-ts {
    font-size: 14px;
    color: rgb(194, 194, 194);
}

.o-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.high-map__title {
    display: flex;
    align-items: center;
    position: absolute;
    z-index: 9;
    margin-top: 3em;
}

.live-text {
    font-size: 16px;
    color: #75daad;
    line-height: 20px;
    /* Align text vertically with the circle */
    display: flex;
    align-items: center;
    padding-left: 50px;
}

.circle {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 7px;
    height: 7px;

    background-color: rgb(7, 185, 7);
    border-radius: 50%;
    animation: flash 5s infinite;
}

@keyframes flash {

    0%,
    100% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }
}

.high-tooltip-small {


    pointer-events: none;
    background: #000000e3;
    color: white;
    font-size: 13px;
    padding: 9px;
    font-weight: 400;
    border-radius: 0.35em;
    box-shadow: 0px 0px 1px 0px;
    transform: translateX(-50%);
    position: absolute;
    line-height: 1.3;

    -webkit-animation: fadein 1s;
    animation: fadein 1s;
}

.app__container-margin-s {
    margin-top: 4em;
}