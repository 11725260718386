.td_details>tr {
    font-size: 11.3px;
    color: #5b5b5b;
}

.td_details>tr>td {
    padding: 0px;
    border: none;
}

.table_economy>tr>td:first-child,
.table_economy_summary>tr>td:first-child,
.table_logistic>tr>td:first-child {
    font-size: 14px;
    color: #515151;
}

.table_economy>tr>td:last-child,
.table_economy_summary>tr>td:last-child,
.table_logistic>tr>td:last-child {
    color: #a3a3a3;
    font-size: 12px;
}

.container>table>tr {
    border-bottom: 1px solid #d3d3d3;
}

.container>table>tr:first-child,
.container>table>tr:last-child {
    border: none;
}

.table_economy {
    grid-template-columns: repeat(auto-fit, minmax(68px, 1fr));
}

.table_economy,
.table_economy_summary,
.table_logistic {
    margin: 6px 0px 6px 6px;
    display: grid;
    gap: 8px 0px;
}

.table_economy>tr,
.table_economy_summary>tr {
    display: grid;
    min-width: 66px;
    width: 100%;
}

.table_economy>tr:last-child {
    grid-column-start: 5;
    grid-column-end: 5;
    grid-row-end: auto;
}

.table_economy_summary>tr:last-child {
    grid-column-start: 3;
    grid-column-end: 3;
    grid-row-end: auto;
}

.table_economy {
    width: 174px;
}

.table_logistic {
    width: 245px;
}

.table_logistic>tr {
    display: inline;
}

.table_logistic>tr>td:nth-child(2) {
    padding-left: 2.5px;
}

.delivery_state {
    flex-direction: row;
    display: inline-flex;
    position: relative;
    max-width: 100%;
    align-items: center;
    font-size: 14px;
}

.delivery_state_i {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    flex-direction: column;
    display: flex;
    position: relative;
    margin-right: 4px;
    background-color: #949494;
}

.delivery_state__successful {
    background-color: #0aaf0a;
}

.delivery_state__cancel {
    background-color: #d51010;
}

.delivery_state__processing {
    background-color: #e7c314;
}

.items-row {
    border-left: none;
    max-width: 30em;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.qty {
    background: #ffed94;
    border-radius: 6px;
    padding: 4px;
    color: #5a5a5a;
    font-size: 13px;
    float: right;
}

.marked {
    background: #ffed94;
    position: inherit;
    display: inherit;
    border-radius: 6px;
    padding: 4px;
    color: #5a5a5a;
    font-size: 13px;
}

.item {
    display: flex;
}

.items-list {
    display: flex;
    flex-direction: column;
}

.item-title {
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 13.7px;
    display: inline-block;
    align-content: center;
    margin: auto 0;
    margin-right: 5px;
}

.td-text-muted {
    color: 505050;
    font-size: 13.2px;
}

.dt-container {
    display: flex;
    flex-direction: column;
    gap: 3.5px;
}

.dt-time {
    font-size: 13px;
    color: #a3a3a3;
}

.dt-date {
    font-size: 13px;
}