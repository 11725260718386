.no_items_container {
    margin: 2rem 0;
}

.no_items_container>div {
    margin-bottom: 2rem;
}

.special_page {
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.no_items_container>h1 {
    font-size: 19px;
    font-weight: 500;
}

.span {
    font-size: 15px;
    color: #515151;
}