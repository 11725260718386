.cl-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    height: 27px;
    width: 27px;
    line-height: 1;
    background-color: #b7bccb59;
    border-radius: 7px;
    margin-right: 1em;
    color: #969899;
}

.cl-header {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0.75em 0;
    border-bottom: 1px dashed #c7c7c7;
}

.cl-btn:hover,
.cl-btn-extended {
    background-color: #c3e9ff;
    color: #5aa7eb;
}

.cl-title {
    font-weight: 500;
}

.cl-content {
    padding: 10px;
    padding-left: 3em;
    font-size: 16px;
}

.cl-content>p>img {
    max-width: 100%;
    margin: 1em auto;
}

.cl-content>p>a {
    color: #1b3ec1;
}