.button {
    background: #2b6cb0;
    outline: none;
    border: none;
    border-radius: 7px;
    padding: 12px;
    color: #fff;
    display: block;
}

.button:hover {
    cursor: pointer;
    background: #29629d;
}

.button:disabled {
    cursor: not-allowed;
    background: #4299e1;
}

.button__muted {
    background: #e9e9e9;
    color: black;
}

.button__muted:hover {
    background-color: #cecece;
}

.button__size_xs {
    padding: 7px;
}

.button_alert {
    background: #ffffff;
    color: black;
    font-size: 12px;
    padding: 7px;
    margin-top: 1em;
    border: 1px solid #ccccccbf;
}

.button_alert:hover {
    cursor: pointer;
    background: #e9e9e9;
}

.btn_special_api {
    background: #bd8300;
}

.btn_special_api:hover {
    background: #855d02;
}

.btn_special_tariffs {
    background: #0b99e9;
}