.select-flex {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.select-icon {
    width: 10px;
    height: 10px;
    border-radius: 22px;
    margin-right: 3px;
    display: inline-block;
}

.select-grid {
    grid-area: 1 / 1 / 2 / 3;
}

.select-wb-muted {
    background: #da00ff52;
}

.select-yam-muted {
    background: #ffe100a6;
}

.select-ozon-muted {
    background: #0070ffbd;
}

.select-wb {
    background: #da00ff;
}

.select-ozon {
    background: #0238ff;
}

.select-yam {
    background: #ffe200;
}

.select-grid>.select-icon {
    opacity: 0.4;
}
