.container {
    background: #fff;
    border-radius: 0.625rem;
    padding: 15px;
    box-shadow: 0 0.1rem 0.75rem 0.25rem rgba(0, 0, 0, 0.01);
}

.container>h2 {
    margin-bottom: 13px;
}

.container-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1em;
}

.container-title {
    display: flex;
}

.container-options {
    display: flex;
    gap: 12px;
}

.container-title>h1 {
    margin: 0;
}

.container-row {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
}

.w_100 {
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 6px;
}


.m_20 {
    margin-top: 20px;
}

.w_part_l {
    width: 100%;
}

.w_part_s {
    width: 25%;
    min-width: 380px;
}

.container-title-s {
    font-weight: 400;
    font-size: 1.2em;
    color: black;
}

.info_row {
    display: flex;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    gap: 5px;
}

.info_container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 9px;
    background: #efefef;
    border-radius: s;
}

.info_row>h1 {
    line-height: 0;
    font-size: 20px;
}

.info_row>h2 {
    font-size: 14px;
}

.info-title {
    padding: 7px;
}


.grid_v {
    grid-auto-rows: auto;
    display: grid;
}

.p_20 {
    margin-bottom: 20px;
}

.container-title-abc {
    font-size: 1.35em;
    font-weight: 500;
    line-height: 2;
}

.s-text {
    font-size: 1.2em;
    font-weight: 400;
}

.container-title-abc>a {
    color: black;
}

.w_half {
    width: 50%;
}

.no_pad {
    padding: 0;
}