/* The container <div> - needed to position the dropdown content */
.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown:after {
    content: "";
    position: absolute;
    left: 0px;
    bottom: -10px;
    display: none;
    vertical-align: bottom;
    width: 100%;
    height: 10px;
    background-color: transparent;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
    width: 100%;
    display: none;
    position: absolute;
    margin-top: 10px;
    background-color: #ffffff;
    z-index: 1;
    border: 1px solid #ccc;
    border-radius: 5px;

    min-width: 100px;
    left: -7px;
    transform: translate(-50%);
}

/* Links inside the dropdown */
.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
    background-color: #ddd;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
    display: block;
}

.dropdown:hover:after {
    display: inline-block;
}


.dropdown__button {
    outline: none;
    border: none;
    background-color: #fff;
}