.form__label {
    display: block;
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: 500;
    color: #505050;
}

.form__input {
    background-color: #fff;
    outline: none;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 13px;
    width: calc(100% - 29px);
}

.form__input:focus {
    background-color: #f7f7f7;
    border: 1px solid #4299e1;
}


.form__input:disabled {
    background-color: #eee;
}

.form__label_bottom {
    margin-top: 5px;
    margin-bottom: 22px;
}

.form__input_max {
    max-width: 22rem;
}

.form-group {
    margin: 14px 0px;
}


/* radio */

.form_radio_btn {
    display: inline-block;
    margin-right: 10px;
}

.form_radio_btn input[type=radio] {
    display: none;
}

.form_radio_btn label {
    display: inline-block;
    cursor: pointer;
    line-height: 34px;
    border: 1px solid #ccc;
    border-radius: 6px;
    user-select: none;
}

.select-mp .form_radio_btn label {
    padding: 0px 13px;
}

/* Checked */
.form_radio_btn input[type=radio]:checked+label {
    background: #dae9f1;
}

/* Hover */
.form_radio_btn label:hover {
    color: #666;
}

/* Disabled */
.form_radio_btn input[type=radio]:disabled+label {
    background: #efefef;
    color: #666;
}

.form__label-info {
    display: block;
    margin-top: 2px;
}

/* selector */

.form__select {
    padding: 9px 0px 9px 12px;
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box;
    outline: none;

    background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>") no-repeat;
    background-position: calc(100% - 0.75rem) center !important;
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    appearance: none !important;
    padding-right: 2rem !important;

}

.form-group_btn {
    display: flex;
    gap: 1em;
}