.tr_score_a {
    background-color: #e1ffe0;
}

.tr_score_B {
    background-color: #fcffd6;
}

.tr_score_C {
    background-color: #ffefef;
}