.h_400 {
    height: 400px;
}

.no_data {
    font-size: 3em;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #a7a7a7;
}

.nd_small {
    font-size: 2em;
    height: 100%;
}