.header {
    padding: 10px 0px;
    background: white;
    border-bottom: 1px solid #ccc;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
}

.header__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 38px;
    padding: 0 24px;
}

.header__link {
    font-size: 15px;
    border-radius: 8px;
    padding: 7px 10px;
}

.header__link,
.header__link_i {
    display: inline-flex;
}

.header__parent {
    display: flex;
    align-items: center;
}

.header__logo>h2 {
    font-weight: 400;
}

.header__auth {
    display: flex;
    align-items: center;
}

.header__link-active {
    background-color: #e7eef7;
}

.header__link:last-child {
    margin-left: 0;
}

.header__link:hover {
    background-color: #f1f1f1;
}

.header__link-active:hover {
    background-color: #f1f1f1;
}

.header__links {
    display: flex;
    gap: 0.35em;
    margin-left: 1em;
    align-items: center;
    flex-wrap: wrap;
}

.header__logo {
    background-image: url('../../../public/logo_small.png');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0px;
    margin: 0px;
    width: 99px;
    height: 29px;

}