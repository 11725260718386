.tooltip-title {
    margin-left: 19px;
}

.tooltip-box,
.tooltip-title {
    font-size: 13px;
    font-weight: 400;
}

.tooltip-fees {
    margin-top: 10px;
    display: inline-block;
}